import React, { useCallback, useEffect } from "react";

import { useContext, useState } from "react";
// @mui
import {
  InputBase,
  IconButton,
  useTheme,
  Stack,
  Box,
  LinearProgress,
  CircularProgress,
  Typography,
  Checkbox,
  Collapse,
  Button,
} from '@mui/material';
// components
import { ChatContext } from '../context';
import { useWidgetSettingsContext } from '../settings';
import Iconify from '../components/iconify';
import { uploadVoiceInput } from '../lib/storage';
import { generateTranscription, widgetDB } from '../lib/firebase';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useBoolean } from '../hooks/use-boolean';
import MediaRecorder from './Polyfill/index.js';
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';

// ----------------------------------------------------------------------

const planMessageCount = {
  price_1PTRURERYpQbaFQQuPcJwYiO: 30,
  price_1PTRTWERYpQbaFQQKEyLyxyl: 2500,
  price_1PaIEXERYpQbaFQQtlYfdbgc: 2500,
  price_1Q0vCZERYpQbaFQQVZplCYhM: 3500,
  price_1Q0vDKERYpQbaFQQ0t4plQhs: 3500,
  price_1Q0vEGERYpQbaFQQQ5V4gtEM: 14500,
  price_1Q0vEnERYpQbaFQQBCL6byJK: 14500,
  price_1Q0vFGERYpQbaFQQpioSZpej: 50000,
  price_1Q0vFoERYpQbaFQQPl2ApLCj: 50000,
  //
  price_1PTRUTERYpQbaFQQt5ZlagKB: 6000,
  price_1PaIJMERYpQbaFQQChyd9yei: 6000,
  price_1PTRUOERYpQbaFQQrF46V32Y: 12000,
  price_1PaIL0ERYpQbaFQQT9A8GZeB: 12000,
  price_1PTRUQERYpQbaFQQEAISy0Kc: 50000,
  price_1PaIMPERYpQbaFQQQV7MqVdW: 50000,
};

var audioContext: AudioContext;

export default function ChatMessageInput() {
  const [message, setMessage] = useState('');
  const {
    status,
    sendMessage,
    chatId,
    messages,
    isAcceptedTerms,
    isIgnoreTerms,
    setVoiceIsReady,
    waitingForNewResponse,
    setWaitingForNewResponse,
  } = useContext(ChatContext);
  const settings = useWidgetSettingsContext();

  const isUserConsentRequired = settings.acceptConsent === 'required';

  const isUserConsentOptional = settings.acceptConsent === 'optional';

  const [prevAssistMesId, setPrevAssistMesId] = useState(
    [...messages].reverse().find((m) => m.role === 'assistant')?.id,
  );

  const isUserConsentDisabled =
    settings.acceptConsent === 'disabled' || !settings.acceptConsent;

  const handleSend = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Enter' && status !== 'typing') {
      if (message) {
        if (settings.assistant) {
          sendMessage(message, messages);
          if (settings?.useTextToVoice && settings.useTextToVoice !== 'false') {
            audioContext = new (window.AudioContext ||
              (window as any).webkitAudioContext)();
            setWaitingForNewResponse(true);
            setVoiceIsReady?.(false);
          }
          setPrevAssistMesId(
            [...messages].reverse().find((m) => m.role === 'assistant')?.id,
          );
        } else {
          settings.setSettings('errorSelectingAssistant', true);
        }
      }
      setMessage('');
    }
  };

  const handleClickSend = () => {
    if (message) {
      if (settings.assistant) {
        sendMessage(message, messages);
        if (settings?.useTextToVoice && settings.useTextToVoice !== 'false') {
          audioContext = new (window.AudioContext ||
            (window as any).webkitAudioContext)();
          setWaitingForNewResponse(true);
          setVoiceIsReady?.(false);
        }
        setPrevAssistMesId(
          [...messages].reverse().find((m) => m.role === 'assistant')?.id,
        );
      } else {
        settings.setSettings('errorSelectingAssistant', true);
      }
    }
    setMessage('');
  };

  const [isTranscribing, setIsTranscribing] = useState(false);
  const db = widgetDB;

  const userRef = doc(db, 'users', settings.ownerUid || '1');

  const { data: user } = useFirestoreDocData(userRef);

  const isFreeUser =
    user?.stripe?.priceId === 'price_1PTRURERYpQbaFQQuPcJwYiO' ||
    !user?.stripe?.priceId;

  const userLeadsCount = user?.leads?.count || 0;

  const userReachLeadLimit = userLeadsCount > 2;

  const totalMessageCredits =
    ((planMessageCount as any)[
      user?.stripe?.priceId || 'price_1PTRURERYpQbaFQQuPcJwYiO'
    ] as any) +
    (user?.stripe?.extraMessages?.isPaid
      ? user?.stripe?.extraMessages?.count || 0
      : 0) *
      2000 +
    (user?.stripe?.extraMessages1?.isPaid
      ? user?.stripe?.extraMessages1?.count || 0
      : 0) *
      1000 +
    (user?.stripe?.extraMessages2?.isPaid
      ? user?.stripe?.extraMessages2?.count || 0
      : 0) *
      2000 +
    (user?.stripe?.extraMessages3?.isPaid
      ? user?.stripe?.extraMessages3?.count || 0
      : 0) *
      10000;

  const limitReached =
    user?.stripe?.priceId === 'price_1PTRUQERYpQbaFQQEAISy0Kc' ||
    user?.stripe?.priceId === 'price_1PaIMPERYpQbaFQQQV7MqVdW'
      ? false
      : (user?.messages?.count || 0) > totalMessageCredits;

  const getTranscriptions = async (blob: Blob, chatId?: string) => {
    try {
      const filePath = await uploadVoiceInput({
        file: blob,
        widgetId: settings.widgetId,
        chatId: 'test-assistant',
      });

      await generateTranscription({
        filePath,
        widgetId: settings.widgetId,
      }).then((res) => {
        const text = res.data.text;
        if (text) {
          if (settings.assistant) {
            sendMessage(text, messages);
            if (
              settings?.useTextToVoice &&
              settings.useTextToVoice !== 'false'
            ) {
              audioContext = new (window.AudioContext ||
                (window as any).webkitAudioContext)();
              setWaitingForNewResponse(true);
              setVoiceIsReady?.(false);
            }
            setPrevAssistMesId(
              [...messages].reverse().find((m) => m.role === 'assistant')?.id,
            );
          } else {
            settings.setSettings('errorSelectingAssistant', true);
          }
          recorderControls.recordingBlob = undefined;
        }
      });
    } catch (error) {
    } finally {
      setIsTranscribing(false);
    }
  };

  const recorderControls = useAudioRecorder();

  const theme = useTheme();

  const isHideRecord = useBoolean(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && !window?.MediaRecorder) {
      window.MediaRecorder = MediaRecorder as any;
    } else if (!window?.MediaRecorder) {
      isHideRecord.onTrue();
    }
  }, [MediaRecorder, typeof window]);

  useEffect(() => {
    let visualizer = document.querySelector(
      '.audio-recorder-visualizer canvas',
    ) as HTMLCanvasElement;

    const interval = setInterval(() => {
      visualizer = document.querySelector(
        '.audio-recorder-visualizer canvas',
      ) as HTMLCanvasElement;
      if (visualizer) {
        visualizer.width = 600;
        visualizer.height = 100;
        clearInterval(interval);
      }
    }, 100);
  }, [recorderControls.isRecording]);

  useEffect(() => {
    if (!recorderControls.recordingBlob) return;
    setIsTranscribing(true);
    getTranscriptions(recorderControls.recordingBlob, chatId as any);
  }, [recorderControls.recordingBlob]);

  const [isSentLimitMessage, setIsSentLimitMessage] = useState(false);

  useEffect(() => {
    const sentOwnerMessage = async () => {
      try {
        if (!isSentLimitMessage) {
          const mailDoc = doc(collection(db, 'new-leads-mail'));
          setIsSentLimitMessage(true);

          await setDoc(mailDoc, {
            to: [user?.email],
            message: {
              subject: 'Message Limit Reached',
              html: `<h4>Hi ${user?.firstName},</h4><p>Customers are trying to reach you, but you have reached the message credits limit.
              <p>
              In the meantime that Ai chatbot will display:
              <div>
              <code style="color: red">We are unavailable at the moment. Please check again later</code>
              <div>
              </p>
              <p>
              To stay in touch with your customers please consider <a href="https://www.platformconnection.com/pricing" target="_blank">upgrading</a> your plan.
              </p>
              <p>In any case starting next month the message counter will reset.</p>
              <p>Thank you</p>
              `,
            },
          });
        }
      } catch (error) {
        console.log('sentOwnerMessage limit reached', error);
        setIsSentLimitMessage(false);
      }
    };

    if (limitReached && user?.email && !isFreeUser) {
      sentOwnerMessage();
    }
  }, [limitReached, isSentLimitMessage, user?.email, isFreeUser]);

  const isAccepting = useBoolean(false);

  const [isAcceptedTermsLocal, setIsAcceptedTerms] = useState(isAcceptedTerms);

  useEffect(() => {
    if (isAcceptedTerms) {
      setIsAcceptedTerms(isAcceptedTerms);
    }
  }, [isAcceptedTerms]);

  const setAcceptTerms = async () => {
    isAccepting.onTrue();
    try {
      const chatDocRef = doc(
        db,
        `widgets/${settings.widgetId || '1'}/chats`,
        chatId || '1',
      );

      await updateDoc(chatDocRef, {
        isAcceptTerms: true,
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      setIsAcceptedTerms(true);
      console.log('setAcceptTerms', error);
    } finally {
      isAccepting.onFalse();
      setIsAcceptedTerms(true);
      localStorage.setItem('terms_status', 'accepted');
    }
  };

  const setIgnoreTerms = async () => {
    try {
      const chatDocRef = doc(
        db,
        `widgets/${settings.widgetId || '1'}/chats`,
        chatId || '1',
      );

      await updateDoc(chatDocRef, {
        isIgnoreTerms: true,
      });
    } catch (error) {
      console.log('setAcceptTerms', error);
      setIsAcceptedTerms(true);
    } finally {
      localStorage.setItem('terms_status', 'ignored');

      setIsAcceptedTerms(true);
    }
  };

  console.log('isAcceptedTermsLocal', isAcceptedTermsLocal);

  const disableButtons = !isAcceptedTermsLocal && !isUserConsentDisabled;
  useEffect(() => {
    const lasAssistMes = [...messages]
      .reverse()
      .find((m) => m.role === 'assistant');
    const lasAssistMesId = lasAssistMes?.id;
    const assistRes = lasAssistMes?.content;


    if (
      waitingForNewResponse &&
      lasAssistMesId !== prevAssistMesId &&
      assistRes &&
      settings?.useTextToVoice &&
      settings.useTextToVoice !== 'false'
    ) {
      setWaitingForNewResponse(false);
      setPrevAssistMesId(lasAssistMesId);
      playStreamedAudio(
        assistRes,
        settings.useTextToVoice ? settings.useTextToVoice : 'alloy',
        settings.assistant?.openai?.apiKeyId || 'default',
        settings.ownerUid!,
        setVoiceIsReady,
      );
    }
  }, [
    waitingForNewResponse,
    messages.length,
    prevAssistMesId,
    settings?.useTextToVoice,
  ]);

  return (
    <Box
      sx={{
        width: 1,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
      }}
      dir={settings.direction === 'rtl' ? 'rtl' : 'ltr'}
    >
      {limitReached || (isFreeUser && userReachLeadLimit) ? (
        <Stack>
          <Typography
            textAlign="center"
            color="error"
            sx={{
              width: 1,
              p: 2,
            }}
            variant="subtitle2"
          >
            We are unavailable at the moment. Please check again later
          </Typography>
        </Stack>
      ) : recorderControls.isRecording ? (
        <Stack
          direction={settings.direction === 'rtl' ? 'row-reverse' : 'row'}
          alignItems="center"
          sx={{
            flex: 1,
            '& .audio-recorder-mic, .audio-recorder-options': {
              display: 'none',
            },
            '& .audio-recorder-timer': {
              // fontFamily: primaryFont.style.fontFamily,
              color: theme.palette.text.primary,
            },
            '& .audio-recorder': {
              boxShadow: 'none',
              p: 1,
              pr: 3,
              width: 1,
              border: 'none',
              borderRadius: 0,
              height: 56,
              bgcolor: theme.palette.background.paper,
            },
            '& .audio-recorder-visualizer canvas': {
              width: 1,
              height: 30,
            },
          }}
        >
          <AudioRecorder
            recorderControls={recorderControls}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            onNotAllowedOrFound={(err) => console.table(err)}
            downloadFileExtension="mp3"
            showVisualizer
          />
          <IconButton
            draggable="true"
            size="small"
            onClick={recorderControls.stopRecording}
            sx={{ mr: 3 }}
          >
            <Iconify icon="icon-park-outline:voice-off" width={23} />
          </IconButton>
        </Stack>
      ) : (
        <>
          {disableButtons && (
            <Stack
              sx={{
                left: 8,
                right: 8,
                position: 'absolute',
                top: -10,
                transform: 'translateY(-100%)',
                pt: 4,
                pb: 4,
                pr: 4,
                pl: 2,
                flexDirection: 'column',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'background.default'
                    : 'grey.300',
                borderRadius: 3,
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'center',
                  color: 'text.primary',
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    height: 36,
                    width: 36,
                  }}
                >
                  {isAccepting.value ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Checkbox
                      checked={isAcceptedTermsLocal}
                      onChange={(event) => setAcceptTerms()}
                    />
                  )}
                </Stack>
                <Typography
                  variant="body2"
                  fontWeight={600}
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  I consent to the processing of my personal data and agree to
                  receive SMS, emails, and voice calls
                </Typography>

                {isUserConsentOptional && (
                  <IconButton
                    onClick={() => setIgnoreTerms()}
                    sx={{
                      position: 'absolute',
                      right: 6,
                      top: 6,
                    }}
                  >
                    <Iconify icon="mingcute:close-fill" width={20} />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          )}
          <InputBase
            disabled={isTranscribing || disableButtons}
            value={isTranscribing ? 'Transcribing' : message}
            onKeyUp={handleSend}
            onChange={(event) => setMessage(event.target.value)}
            placeholder={settings.inputPlaceholder}
            startAdornment={
              settings.direction === 'rtl' && (
                <Stack direction="row" sx={{ flexShrink: 0 }}>
                  {/* {!recorderControls.isRecording && !isHideRecord.value && (
                    <Stack
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton
                        disabled={
                          isTranscribing ||
                          status === 'typing' ||
                          disableButtons
                        }
                        draggable="true"
                        size="small"
                        onClick={() => {
                          recorderControls.startRecording();
                        }}
                        sx={{ mr: 1 }}
                      >
                        <Iconify icon="icon-park-solid:voice" width={20} />
                      </IconButton>
                    </Stack>
                  )} */}
                  <IconButton
                    disabled={status === 'typing' || disableButtons}
                    size="medium"
                    onClick={handleClickSend}
                    sx={{}}
                  >
                    <Iconify icon="streamline:mail-send-email-message-solid" />
                  </IconButton>
                </Stack>
              )
            }
            endAdornment={
              settings.direction !== 'rtl' && (
                <Stack direction="row" sx={{ flexShrink: 0 }}>
                  {/* {!recorderControls.isRecording && !isHideRecord.value && (
                    <Stack
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        disabled={isTranscribing || status === "typing" || disableButtons}
                        draggable="true"
                        size="small"
                        onClick={() => {
                          recorderControls.startRecording();
                        }}
                        sx={{ mr: 1 }}
                      >
                        <Iconify icon="icon-park-solid:voice" width={20} />
                      </IconButton>
                    </Stack>
                  )} */}
                  <IconButton
                    disabled={status === 'typing' || disableButtons}
                    size="medium"
                    onClick={handleClickSend}
                    sx={{}}
                  >
                    <Iconify icon="streamline:mail-send-email-message-solid" />
                  </IconButton>
                </Stack>
              )
            }
            sx={{
              px: 2,
              height: 56,
              flex: 1,
              flexShrink: 0,
              opacity: !isAcceptedTermsLocal ? 0.5 : 1,
              borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
              '& input::placeholder': {
                fontWeight: 500,
              },
            }}
          />
        </>
      )}
    </Box>
  );
}

async function playStreamedAudio(
  text: string,
  voiceType: string,
  apiKeyId: string,
  userUid: string,
  setVoiceIsReady?: (isReady: boolean) => void,
) {
  try {
    let timeout = false;
    setTimeout(() => {
      timeout = true;
      setVoiceIsReady && setVoiceIsReady(true);
    }, 30000);
    const response = await fetch(
      'https://texttospeech-csu7atsmpq-uc.a.run.app',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text,
          voiceType: voiceType || 'alloy',
          apiKeyId,
          userUid,
        }),
      },
    );

    if (!response.ok) {
      console.error('Failed to fetch audio stream');
      throw new Error('Failed to fetch audio stream');
    }

    console.log('Fetching audio stream from backend...');

    const reader = response.body?.getReader();
    if (!reader) {
      console.error('Response body does not have a reader.');
      throw new Error('Response body is not a readable stream.');
    }

    const audioChunks: Uint8Array[] = [];
    console.log('Reading audio chunks...');

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      audioChunks.push(value!);
    }

    console.log('Audio chunks received:', audioChunks.length);

    setVoiceIsReady && setVoiceIsReady(true);
    // Combine chunks into a single Blob
    const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' });

    if (timeout) {
      console.log('Timeout reached, not playing audio.');
      return;
    }

    if (audioContext.state === 'suspended') {
      console.log('Resuming AudioContext due to user gesture requirement...');
      await audioContext.resume();
    }

    if (timeout) {
      console.log('Timeout reached, not playing audio.');
      return;
    }

    console.log('Decoding audio data...');
    const audioBuffer = await audioContext.decodeAudioData(
      await audioBlob.arrayBuffer(),
    );

    if (timeout) {
      console.log('Timeout reached, not playing audio.');
      return;
    }

    console.log('Playing audio...');
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.start();
  } catch (error) {
    setVoiceIsReady && setVoiceIsReady(true);
    console.error('Error playing streamed audio:', error);
  }
}